import {
    ArrayInput,
    BooleanInput,
    Create,
    ImageField,
    ImageInput,
    number,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';
import { isAdmin, isFranchisee, isPartnerService, isEmployee } from '../utils';
import { LocalizedDataInput } from '../localizedDataInput/LocalizedDataInput';
import { countryOptions } from '../constants/countries';
import { DateInput, TimeInput } from 'react-admin-date-inputs';
import { tariffOptionsLocations } from '../constants/tariffs';
import React from 'react';
import { EditableScheduleField, Timezone, TypeField } from './components';
import moment from 'moment';
import { Field } from 'redux-form';
import { optionRenderer, managerOptionRenderer } from './utils';
import { stylesWide } from '../utils';
import { withStyles } from '@material-ui/core/styles';

const AddLocation = withStyles(stylesWide)(({ classes, permissions, ...props }) => (
    <Create classes={classes} {...props}>
        <SimpleForm
            defaultValue={{
                schedule: {
                    1: [['', '']],
                    2: [['', '']],
                    3: [['', '']],
                    4: [['', '']],
                    5: [['', '']],
                    6: [['', '']],
                    7: [['', '']],
                },
                timezone: moment().format('Z'),
            }}
        >
            {!isPartnerService(permissions) && (
                <ReferenceInput
                    perPage={10000}
                    label="Id договора c партнером"
                    source="contract_id"
                    reference="partner_contracts"
                    filter={{ partner: true }}
                    allowEmpty
                    parse={value => value || null}
                    className={classes.wide}
                >
                    <SelectInput optionText={optionRenderer} className={classes.wide}/>
                </ReferenceInput>
            )}
            {(isAdmin(permissions) || isPartnerService(permissions)) && (
                <ReferenceInput
                    perPage={10000}
                    label="Id договора c сервисным партнером"
                    source="partner_service_contract_id"
                    reference={
                        isPartnerService(permissions)
                            ? 'partner_contracts'
                            : 'partner_service_contracts'
                    }
                    filter={{ partner_service: true }}
                    allowEmpty
                    parse={value => value || null}
                    className={classes.wide}
                >
                    <SelectInput optionText={optionRenderer} className={classes.wide}/>
                </ReferenceInput>
            )}
            {isEmployee(permissions) && (
                <ReferenceInput
                    perPage={10000}
                    label="Id договора c менеджером"
                    source="manager_contract_id"
                    reference='manager_contracts'
                    filter={{ manager: true }}
                    allowEmpty
                    parse={value => value || null}
                    className={classes.wide}
                >
                    <SelectInput optionText={managerOptionRenderer} className={classes.wide}/>
                </ReferenceInput>
            )}
            <BooleanInput
                disabled={!isAdmin(permissions)}
                label="Депозит"
                source="deposit_feature"
                className={classes.wide}
            />
            <LocalizedDataInput
                label="Название"
                source="name_localized"
                type="edit"
                className={classes.wide}
            />
            <LocalizedDataInput
                label="Адрес"
                source="address_localized"
                type="edit"
                className={classes.wide}
            />
            <SelectInput
                label="Страна"
                source="country"
                choices={countryOptions}
                className={classes.wide}
            />
            <LocalizedDataInput
                label="Город"
                source="city_localized"
                type="edit"
                className={classes.wide}
            />
            <SelectInput
                label="Категория"
                source="category"
                choices={[
                    { id: 'Общепит', name: 'Общепит' },
                    { id: 'Путешествия', name: 'Путешествия' },
                    { id: 'Спорт', name: 'Спорт' },
                    { id: 'Красота и здоровье', name: 'Красота и здоровье' },
                    { id: 'Развлечения и досуг', name: 'Развлечения и досуг' },
                    { id: 'Транспорт', name: 'Транспорт' },
                    { id: 'Разное', name: 'Разное' },
                    { id: 'Другое', name: 'Другое' },
                ]}
                allowEmpty
                className={classes.wide}
            />
            <TypeField
                label="Тип"
                source="type"
                className={classes.wide}
            />
            <TextInput
                label="Широта"
                source="latitude"
                validate={number()}
                className={classes.wide}
            />
            <TextInput
                label="Долгота"
                source="longitude"
                validate={number()}
                className={classes.wide}
            />
            <TimeInput
                label="Начало работы"
                source="start_daytime"
                options={{ ampm: false }}
                className={classes.wide}
            />
            <TimeInput
                label="Конец работы"
                source="end_daytime"
                options={{ ampm: false }}
                className={classes.wide}
            />
            <TextInput
                label="Время работы"
                source="work_duration"
                validate={number()}
                className={classes.wide}
            />
            <Field
                name={'timezone'}
                label={'Временная зона'}
                component={Timezone}
                className={classes.wide}
            />
            <EditableScheduleField source={'schedule'} className={classes.wide}/>
            <LocalizedDataInput
                label="Гайд"
                source="guide_localized"
                type="edit"
                className={classes.wide}
            />
            <TextInput
                label="Комментарии"
                source="comment"
                className={classes.wide}
            />
            <SelectInput
                label="Статус"
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'inactive', name: 'inactive' },
                ]}
                className={classes.wide}
            />
            <SelectInput
                label="Видимость на карте"
                source="visibility"
                choices={[
                    { id: 'visible', name: 'visible' },
                    { id: 'hidden', name: 'hidden' },
                ]}
                className={classes.wide}
            />
            <NumberInput
                label="Опция тарифа"
                source="tariff_option"
                validate={number()}
                className={classes.wide}
            />
            <SelectInput
                label="Опция тарифа"
                source="tariff_option"
                choices={tariffOptionsLocations}
                allowEmpty
                className={classes.wide}
            />
            <SelectInput
                label="Опция тарифа первой сессии"
                source="tariff_option_first_session"
                choices={tariffOptionsLocations}
                allowEmpty
                className={classes.wide}
            />
            {isFranchisee(permissions) && (
                <DateInput
                    label="Бонус с"
                    source="bonus_start_date"
                    options={{ format: 'dd.MM.YYYY' }}
                    className={classes.wide}
                />
            )}
            <ImageInput
                source="photo"
                label="Фото"
                accept="image/*"
                multiple={true}
                className={classes.wide}
            >
                <ImageField
                    source="src"
                    title="name"
                    className={classes.wide}
                />
            </ImageInput>
            {isAdmin(permissions) && (
                <ArrayInput
                    label="Внешние промоакции"
                    source="promos"
                    className={classes.wide}
                >
                    <SimpleFormIterator>
                        <ReferenceInput
                            label="Промо"
                            source="promo_id"
                            reference="external_promo"
                            allowEmpty
                            className={classes.wide}
                        >
                            <SelectInput className={classes.wide} optionText={choice => `${choice.id} (${choice.name})`} />
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            )}
            <TextInput
                disabled
                label="Время создания"
                source="created"
                className={classes.wide}
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
                className={classes.wide}
            />
        </SimpleForm>
    </Create>
));
export default AddLocation;
